<template>
  <div class="container" v-if="accountBalance">
    Total:<span class="green">${{ (totalSum + usd + usdt).toFixed(2) }}</span
    >&nbsp;&nbsp; <span class="orange">USD</span>:
    {{ usd.toFixed(2) }}&nbsp;&nbsp; <span class="orange">USDT</span>:
    {{ usdt.toFixed(2) }}
    <br />
    <ul class="list" v-for="order in orders" :key="order">
      <li data-order-id="{{order.orderId}}">
        <div>
          [{{ order.side[0] }}]
          <code>{{ order.currency1 }} / {{ order.currency2 }}</code>
          {{ order.averagePrice }} (lastPrice={{
            fetchLastPrice(order.currency1, order.currency2).value
          }}
          status={{ order.status }})
        </div>
      </li>
    </ul>
    <br />
    <ul class="list" v-for="coin in coins" :key="coin">
      <li v-if="priceData[coin]">
        <div>
          <a
            class="link"
            :href="`https://www.binance.com/en/trade/${coin}_USDT`"
            target="_blank"
            >{{ coin }}</a
          >
          units: {{ accountBalance[coin].available }}<br />
          (price x units: ${{ priceData[coin].toFixed(4) }})
        </div>
        <div v-if="totalBuy[coin]">buy: -{{ totalBuy[coin].toFixed(2) }}</div>
        <div v-if="totalSell[coin]">sell: {{ totalSell[coin].toFixed(2) }}</div>
        <div v-if="netProfit[coin]">
          net:
          <span
            :class="{ green: netProfit[coin] > 0, red: netProfit[coin] < 0 }"
          >
            {{ netProfit[coin].toFixed(2) }}
          </span>
        </div>
      </li>
      <template
        v-for="archivedOrder in archived[coin]"
        :key="archivedOrder.orderId"
      >
        <li v-if="archivedOrder.status == 'd'">
          <div>{{ archivedOrder.type }}</div>
          <div>== amount: {{ archivedOrder["a:" + coin + ":cds"] }}</div>
          <div>
            == price{{ archivedOrder.status }}
            {{
              (
                parseFloat(archivedOrder["a:" + coin + ":cds"]) /
                parseFloat(archivedOrder["a:USD:cds"])
              ).toFixed(4)
            }}
          </div>
        </li>
      </template>
    </ul>
  </div>
</template>

<script setup>
/* eslint-disable */
import { ref, reactive, onMounted, computed } from "vue";
const crypto = require("crypto");
const apiKey = process.env.VUE_APP_API_KEY;
const apiSecret = process.env.VUE_APP_API_SECRET;
const clientId = process.env.VUE_APP_API_CLIENT_ID;

const accountBalance = reactive({});
const ticker = reactive({});
const archived = reactive({});
const tradeHistory = reactive({});
const orderBook = reactive({});
const orders = ref([]);
const lastPrice = ref({});
const tradingOrders = reactive({});
const accountStatus = reactive({});
const cexTradingPubRequest = async (action, params = {}) => {
  const response = await fetch(`https://cex.io/api/${action}`, {
    method: "GET",
    headers: { Accept: "application/json", "Access-Control-Allow-Origin": "*" },
  });
  const json = await response.json();
  return json;
};
const cexTradingPrivRequest = async (action, params = {}) => {
  const timestamp = parseInt(Date.now() / 1000);
  const payload = action + timestamp + JSON.stringify(params);
  const signature = crypto
    .createHmac("sha256", process.env.VUE_APP_TRADING_API_SECRET)
    .update(payload)
    .digest("base64");
  //https://api.plus.cex.io
  const response = await fetch(`https://api.plus.cex.io/rest/${action}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "X-AGGR-KEY": process.env.VUE_APP_TRADING_API_KEY,
      "X-AGGR-TIMESTAMP": timestamp,
      "X-AGGR-SIGNATURE": signature,
      Accept: "*/*",
    },
    body: JSON.stringify(params),
  });
  const json = await response.json();
  return json;
};
const getTradeHistory = async () => {
  tradeHistory = await cexTradingPrivRequest("get_trade_history", {
    pair: "SOL-USDT",
  });
};
const fetchLastPrice = async (currency1, currency2) => {
  const pair = `${currency1}/${currency2 == "USDT" ? "USD" : currency2}`;
  if (lastPrice[pair]) {
    return lastPrice[pair];
  }
  const parsedJson = await cexTradingPubRequest(`ticker/${pair}`);
  lastPrice[pair].value = parsedJson.last;
  return lastPrice[pair].value;
};
const getOrders = async () => {
  //  {
  //           "orderId": "715317",
  //           "clientOrderId": "1706106386685",
  //           "clientId": "up144729044",
  //           "accountId": null,
  //           "status": "FILLED",
  //           "statusIsFinal": true,
  //           "currency1": "SOL",
  //           "currency2": "USDT",
  //           "side": "BUY",
  //           "orderType": "Market",
  //           "timeInForce": "IOC",
  //           "comment": null,
  //           "rejectCode": null,
  //           "rejectReason": null,
  //           "initialOnHoldAmountCcy1": null,
  //           "initialOnHoldAmountCcy2": "14.34286900",
  //           "executedAmountCcy1": "0.16630362",
  //           "executedAmountCcy2": "14.30710125",
  //           "requestedAmountCcy1": null,
  //           "requestedAmountCcy2": "14.30710125",
  //           "originalAmountCcy2": "14.34286900",
  //           "feeAmount": "0.03576775",
  //           "feeCurrency": "USDT",
  //           "price": null,
  //           "averagePrice": "86.03",
  //           "clientCreateTimestamp": 1706106386685,
  //           "serverCreateTimestamp": 1706106389236,
  //           "lastUpdateTimestamp": 1706106392237,
  //           "expireTime": null,
  //           "effectiveTime": null
  //       },
  const ordersResponse = await cexTradingPrivRequest("get_my_orders", {
    archived: true,
    serverCreateTimestampFrom: Date.now() - 7 * 24 * 60 * 60 * 1000, // 7 days
    serverCreateTimestampTo: Date.now(),
  });
  orders.value = ordersResponse.data;
};
const getAccountStatus = async () => {
  const accountStatus1 = await cexTradingPrivRequest(
    "get_my_transaction_history",
    {
      accountId: "",
      type: "",
      pageSize: 10,
      pageNumber: 1,
      sortOrder: "ASC",
    }
  );
};
const cexPrivRequest = async (url, input = {}) => {
  const nonce = generateNonce();
  const message = nonce.toString() + clientId + apiKey;
  const signature = crypto
    .createHmac("sha256", apiSecret)
    .update(message)
    .digest("hex")
    .toUpperCase();
  const inputBody = {
    key: apiKey,
    signature: signature,
    nonce: nonce,
    ...input,
  };

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Accept: "*/*",
    },
    body: JSON.stringify(inputBody),
  });
  const json = await response.json();
  return json;
};
const cexPubRequest = async (url, inputBody = {}) => {
  const postRequest = Object.keys(inputBody).length > 0;
  const response = await fetch(url, {
    method: postRequest ? "POST" : "GET",
    headers: { Accept: "*/*", "Content-Type": "application/json" },
    body: postRequest ? JSON.stringify(inputBody) : undefined,
  });
  const json = await response.json();
  return json;
};
const coins = computed(() => {
  return Object.keys(accountBalance);
});
const generateNonce = () => {
  return new Date().getTime();
};
const loadData = async () => {
  getOrders();
  getAccountStatus();
};
const loadData2 = async () => {
  cexPrivRequest("/api/balance/").then(async (body) => {
    Object.entries(body).forEach(([currency, balanceInfo]) => {
      const currentBalance = parseFloat(balanceInfo.available);
      if (currentBalance > 0) {
        accountBalance[currency] = balanceInfo;
      }
    });
    for (const coin of Object.keys(accountBalance)) {
      if (coin === "USD") {
        continue;
      }
      const pair = `${coin.toUpperCase()}/USD`;
      const parsedJson = await cexPubRequest(`/api/ticker/${pair}`);
      ticker[coin] = parsedJson;
      // archived[coin] = await cexAuth2.archived_orders(pair);
      archived[coin] = await cexPrivRequest(`/api/archived_orders/${coin}/USD`);
      archived[`${coin}/USDT`] = await cexPrivRequest(
        `/api/open_orders/${coin}/USDT`
      );
      // tradeHistory[coin] = await cexPrivRequest(`/api/trade_history/${pair}`);
      // orderBook[coin] = await cexPrivRequest(`/api/order_book/${pair}`);
    }
  });
};

const totalSum = computed(() => {
  return Object.values(priceData.value).reduce((sum, v) => sum + v, 0);
});

const totalBuy = computed(() => {
  return Object.keys(archived).reduce((memo, coin) => {
    console.log(`totalBuy: ${coin} ${JSON.stringify(archived[coin])}`);
    if (archived[coin].length) {
      memo[coin] = archived[coin]
        .filter((v) => v.type === "buy" && v.status == "d")
        .reduce((sum, v) => sum + parseFloat(v["tta:USD"]), 0);
    }
    return memo;
  }, {});
});

const netProfit = computed(() => {
  return Object.keys(archived).reduce((memo, coin) => {
    memo[coin] =
      (-totalBuy[coin] || 0) + (totalSell[coin] || 0) + (priceData[coin] || 0);
    return memo;
  }, {});
  // -totalBuy[coin] + totalSell[coin] + priceData[coin]
});

const totalSell = computed(() => {
  return Object.keys(archived).reduce((memo, coin) => {
    if (archived[coin].length) {
      memo[coin] = archived[coin]
        .filter((v) => v.type === "sell" && v.status == "d")
        .reduce((sum, v) => sum + parseFloat(v["tta:USD"]), 0);
    }
    return memo;
  }, {});
});

const usd = computed(() => {
  return accountBalance["USD"]
    ? parseFloat(accountBalance["USD"].available)
    : 0;
});

const usdt = computed(() => {
  return accountBalance["USDT"]
    ? parseFloat(accountBalance["USDT"].available)
    : 0;
});
const priceData = computed(() => {
  return Object.entries(ticker).reduce((memo, info) => {
    const [coin, price] = info;
    const currentAmount = accountBalance[coin].available;
    if (price && price.last && currentAmount) {
      memo[coin] = parseFloat(price.last) * parseFloat(currentAmount);
    }
    return memo;
  }, {});
});

onMounted(() => loadData());
</script>
<style scoped>
.container {
  font-family: "Monaco";
}
.list {
  text-align: left;
  font-family: "Monaco";
  /*display: inline-flex;*/
}
.link {
  background-color: rgb(121, 215, 238);
  color: white;
  text-decoration: none;
}
.orange {
  background-color: orange;
  color: white;
}
.red {
  background-color: rgb(211, 103, 103);
  color: white;
}
.green {
  background-color: rgb(149, 202, 149);
  color: white;
}
</style>
